/* Profile page container */
.profile-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f3f4f6;
  font-family: Arial, sans-serif;
}

/* Profile card styling */
.profile-card {
  background-color: #ffffff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 400px;
  width: 100%;
}

/* Welcome message styling */
.profile-card h1 {
  font-size: 1.8rem;
  color: #333;
  margin-bottom: 20px;
}

/* User info styling */
.user-info {
  font-size: 1rem;
  color: #555;
  margin-bottom: 30px;
}

/* Logout button styling */
.logout-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #ffffff;
  background-color: #4A707A;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background-color: #3d5e63;
}