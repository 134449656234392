/* Fullscreen layout */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', sans-serif; /* Body font */
  background-color: #f3f4f6;
}

.sign-up-page {
  display: flex;
  width: 100vw; /* Full screen width */
  height: 100vh; /* Full screen height */
  overflow: hidden;
}

/* Left section, occupying 52% */
.left-section {
  flex: 0 0 52%; /* 52% width for the left side */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f4f6;
  height: 100vh;
  padding: 0; /* Remove padding */
}

.holiday-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Right section with the sign-up form, occupying 48% */
.signup-section {
  flex: 0 0 48%; /* 48% width for the right side */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 40px;
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  height: 100vh;
}

.logo {
  font-size: 48px; /* Heldane font size for titles */
  font-weight: 600;
  color: #2D6296; /* Dark blue */
  font-family: 'Heldane', serif;
  margin-bottom: 20px;
}

/* .form-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  max-width: 400px;
  flex-direction: column;
  padding: 20px;
  box-sizing: border-box;
  text-align: center; 
} */
 .form-container {
  position: fixed; /* Keeps the container in a fixed position */
  top: 50%; /* Centers it vertically */
  left: 85%; /* Centers within the right half */
  transform: translate(-80%, -50%); /* Adjusts to center based on its size */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px; /* Controls width to make it responsive */
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.form-container h1 {
  font-size: 48px; /* Heldane for titles */
  color: #2D6296;
  margin-bottom: 20px;
}

.form-container h2 {
  font-size: 36px; /* Heading 2 */
  font-family: 'Heldane', serif;
  color: #4AADD2; /* Blue */
  margin-bottom: 20px;
}

.form-container p {
  font-size: 18px; /* Article Body */
  color: #777;
  margin-bottom: 20px;
}

/* Buttons styling */

.signup-buttons button {
  width: 100%;
  max-width: 400px;
  padding: 15px 20px;
  margin-bottom: 15px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  border-radius: '8px';
  border: none;
}

.signup-btn i {
  margin-right: 8px;
}

/* Individual styling for each sign-up button */
.signup-btn.email {
  /* background-color: #1a73e8; */
  background-color: #2D6296;
  color: white;
}

.signup-btn.email:hover {
  background-color: #1667c1;
}

.signup-btn.facebook {
  /* background-color: #4267B2; */
  background-color: #4AADD2;
  color: white;
}

.signup-btn.facebook:hover {
  background-color: #365899;
}

.signup-btn.google {
  /* background-color: #dbdbdb; */
  background-color: #7CD6E5;
  color: white;
}

.signup-btn.google:hover {
  background-color: #c7c7c7;
}

/* Login prompt */
.login-prompt {
  margin-top: 1.5rem;
  font-size: 18px; /* Body text size */
  color: #555;
}

.login-prompt .link {
  color: #4aadd2;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.login-prompt .link:hover {
  text-decoration: underline;
}

/* Terms and conditions */
.terms {
  font-size: 14px; /* Article Author and Date size */
  color: #777;
  margin-top: 1.5rem;
}

.terms a {
  color: #4aadd2;
  text-decoration: none;
}

.terms a:hover {
  text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .sign-up-page {
    flex-direction: column;
  }

  .left-section, .signup-section {
    width: 100%;
    height: auto;
  }

  .holiday-image {
    max-height: 50vh;
  }

  .signup-section {
    padding: 20px;
  }
}
