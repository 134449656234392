/* Fullscreen layout */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  width: 100%;
  font-family: 'Montserrat', sans-serif; /* Updated font */
  background-color: #f6ebcc; /* Light Champagne */
}

.login-page {
  display: flex;
  width: 100vw; /* Full screen width */
  height: 100vh; /* Full screen height */
  overflow: hidden;
}

/* Form Container */
.form-container {
  position: fixed; /* Keeps the container in a fixed position */
  top: 50%; /* Centers it vertically */
  left: 85%; /* Centers within the right half */
  transform: translate(-80%, -50%); /* Adjusts to center based on its size */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px; /* Controls width to make it responsive */
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
}

.form-container h1,
.form-container h2,
.form-container p,
.form-container button,
.form-container input {
  margin: 10px 0;
  width: 100%;
}

.form-container h1 {
  font-family: 'Heldane', serif; /* Updated font for titles */
  font-size: 48px;
  color: #2d6296; /* Dark Blue */
  margin-bottom: 20px;
  right: 16px; 
}

.form-container h2 {
  font-family: 'Heldane', serif;
  font-size: 36px; /* Heading 2 size */
  color: #4aadd2; /* Blue */
  margin-bottom: 20px;
}

.form-container p {
  font-family: 'Montserrat', sans-serif; /* Body text */
  font-size: 18px; /* Article Body */
  color: #2d6296; /* Dark Blue */
}

/* Left section with image */
.left-section {
  flex: 1; /* Allow the image section to occupy full height */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f6ebcc; /* Light Champagne */
  height: 100vh;
  overflow: hidden;
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margin */
}

.holiday-image {
  width: 100%; /* Fill the container width */
  height: 100%; /* Fill the container height */
  object-fit: cover; /* Ensure the entire image fits without distortion */
  object-position: center; /* Center the image */
}

/* Right section with the login options */
.login-section {
  flex: 1; /* Equal flex with left section */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Align content to the center */
  align-items: center;
  padding: 40px;
  background-color: #ffffff; /* White background */
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  height: 100vh;
}

/* Logo Styling */
.logo {
  font-family: 'Heldane', serif;
  font-size: 48px; /* Titles size */
  color: #2d6296; /* Dark Blue */
  margin-bottom: 20px;
  padding-right: 50px;
}

/* Login Buttons */
.login-buttons button {
  width: 100%;
  max-width: 400px;
  padding: 0.75rem;
  margin: 0.5rem 0;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s ease , transform 0.2s ease;
  padding: 15px 20px;
  border-radius: '8px';
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  border: none;
  margin-bottom: 15px;
}

.login-btn.email {
  /* background-color: #1a73e8;  */
  background-color: #2D6296;
  color: white;
}

.login-btn.email:hover {
  background-color: #1667c1; /* Darker hover color */
}

.login-btn.facebook {
  /* background-color: #4267b2; */
  background-color: #4AADD2;

  color: white;
}

.login-btn.facebook:hover {
  background-color: #365899; /* Darker Facebook hover */
}

.login-btn.google {
  /* background-color: #dbdbdb;  */
  background-color: #7CD6E5;
  color: white;
}

.login-btn.google:hover {
  background-color: #c7c7c7; /* Lighter hover */
}

/* Signup Prompt */
.signup-prompt {
  margin-top: 1.5rem;
  font-family: 'Montserrat', sans-serif;
  font-size: 14px; /* Small Text */
  color: #555;
}

.signup-prompt .link {
  color: #4aadd2; /* Blue */
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
}

.signup-prompt .link:hover {
  text-decoration: underline;
}
